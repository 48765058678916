import React from "react"
import {withPrefix} from "gatsby"
// import { Helmet } from "react-helmet"
// import { Link } from "gatsby"

import "../../../../styles/core.css"
import "../../../../styles/styles.css"
import Layout from "../../../../components/Layout"
import MenuOne from "../../../../components/MenuOne"
import MenuTwo from "../../../../components/MenuTwo"
import MenuThree from "../../../../components/MenuThree"
import Content from "../../../../components/Content"
import Gallery from "../../../../components/Gallery"
import MenuFour from "../../../../components/MenuFour"

const galImgs = ['/page-images/hilary-mckee_2.jpg']

export default function OurWork() {
  return (
    <Layout breadcrumbs={["House three"]}>
      <Gallery items={
      galImgs.map(image => ({
          original: withPrefix(image),
          thumbnail: withPrefix(image),
        })
      )}
    />

<Content>
<p>A very contemporary design solution set in a diverse residential development in a Co Down village. Material used in the building and the open interior spaces of this project reflect the progressive thinking behind the client’s design brief. Glazing was designed to maximise the spectacular views to the east of the property over Strangford Lough.</p>
</Content>



      <div class="region region-content-bottom">
        <MenuOne active='our-work' />
        <MenuTwo
          active=""
          items={[
            '/our-work/residential',
            '/our-work/commercial',
            '/our-work/industrial',
            '/our-work/leisure',
            '/our-work/community',
            '/our-work/retail',
          ]} />
        <MenuThree
          items={[
            '/our-work/residential/houses',
            '/our-work/residential/extensions',
            '/our-work/residential/apartments',
            '/our-work/residential/developments',
          ]} />
        <MenuFour
          items={[
            '/our-work/residential/houses/house-one',
            '/our-work/residential/houses/house-two',
            '/our-work/residential/houses/house-three',
            '/our-work/residential/houses/house-four',
            '/our-work/residential/houses/house-five',
            '/our-work/residential/houses/house-six',
            '/our-work/residential/houses/house-seven',
            '/our-work/residential/houses/house-eight',
          ]}
        />
      </div>

  </Layout>

  )
}
